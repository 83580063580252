<template>
  <div id="index">
    <div class="container">
      <div class="text-center">
        <h2 class="mb-4">Добавить технику</h2>
        <b-progress :value="progress" :max="100" class="mb-4" variant="secondary"></b-progress>
        <h5>Укажите вид<br>собственности</h5>
        <hr>
      </div> 
      <b-form class="mb-5">
        <b-form-radio-group v-if="false" v-model="form.property" :options="options.property" value-field="item" text-field="name" stacked></b-form-radio-group>
        <!--
        <b-form-group id="input-group-3" label="Марка:" label-for="select-1">
            <b-form-select @change="onBrandSelected($event)" 
            v-model="form.brand_id" 
            placeholder="Выберите марку" 
            :options="options.brands"
            :state="validateState('brand_id')"
            ></b-form-select>
            <FormInputValidate :vdata="$v.form.brand_id"></FormInputValidate>
        </b-form-group>
        <b-form-group id="input-group-3" label="Модель:" label-for="select-2" v-if="options.models.length">
            <b-form-select 
            v-model="form.model_id" 
            placeholder="Выберите модель" 
            :options="options.models"
            :state="validateState('model_id')"
            ></b-form-select>
            <FormInputValidate :vdata="$v.form.model_id"></FormInputValidate>
        </b-form-group>
        -->
        <b-form-group label="Марка/Модель:" label-for="input-2">
            <b-form-input
                id="input-2"
                v-model="form.modelTitle"
                placeholder="Mitsubishi Fuso Canter"
                :state="validateState('modelTitle')"
                required
                ></b-form-input>
              <FormInputValidate :vdata="$v.form.modelTitle"></FormInputValidate>
        </b-form-group>
        <b-form-group label="Госномер:" label-for="input-2">
            <b-form-input
                id="input-2"
                v-model="form.regnumber"
                placeholder="Госномер"
                :state="validateState('regnumber')"
                required
                ></b-form-input>
              <FormInputValidate :vdata="$v.form.regnumber"></FormInputValidate>
        </b-form-group>
        <div v-if="1 == form.property">
            <label>Собственник:</label>
            <b-form-group>
                <b-form-input
                    id="input-2"
                    v-model="form.ownerFio"
                    placeholder="ФИО собственника"
                    required
                    ></b-form-input>
            </b-form-group>
            <b-form-group>
                <b-form-input
                    id="input-2"
                    v-model="form.pts"
                    placeholder="№ ПТС / СР"
                    required
                    ></b-form-input>
            </b-form-group>
            <b-form-group>
                <b-form-datepicker 
                  id="input-pts_date" 
                  v-model="form.pts_date" 
                  required class="mb-2" 
                  placeholder="Дата выдачи ПТС / СР">
                  </b-form-datepicker>
            </b-form-group>
            <label>Фото ПТС:</label>
            <b-form-group>
                <b-form-file
                  @input="selectPTS"
                  v-model="photo_pts"
                  placeholder="Выберите файл"
                ></b-form-file>
            </b-form-group>
            <label>Водитель:</label>
            <b-form-group>
                <b-form-input
                    id="input-2"
                    v-model="form.driver_fio"
                    placeholder="ФИО водителя"
                    required
                    ></b-form-input>
            </b-form-group>
            <b-form-group>
                <b-form-input
                    id="input-2"
                    v-model="form.driver_license_num"
                    placeholder="№ ВУ"
                    required
                    ></b-form-input>
            </b-form-group>
            <b-form-group>
                <b-form-datepicker 
                  id="input-driver_license_date" 
                  v-model="form.driver_license_date" 
                  required class="mb-2" 
                  placeholder="Дата выдачи ПТС / СР">
                  </b-form-datepicker>
            </b-form-group>
            <label>Фото ВУ:</label>
            <b-form-group>
                <b-form-file
                  @input="selectDL"
                  v-model="photo_dl"
                  placeholder="Выберите файл"
                ></b-form-file>
            </b-form-group>
        </div>
      </b-form>
      <div class="clearfix mt-2">
          <div class="float-left"><a @click="prevStep" class="text-black-50">&larr; Назад</a></div>
          <div class="float-right"><a @click="nextStep" class="text-dark">Следующий шаг &rarr;</a></div>
      </div>
      <br><br>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from 'vuelidate/lib/validators'
import FormInputValidate from "../../Modules/FormInputValidate.vue";

export default {
    name: 'Property',
    components: {
      FormInputValidate
    },
    mixins: [validationMixin],
    props: ['step', 'progress'],
    data() {
        return {
            options: {
              brands: [],
              models: [],
              property: [
                 { item: '1', name: 'Техника/услуга в собственности' },
                 { item: '2', name: 'Не в собственности' }
              ]
            },
            photo_pts: null,
            photo_dl: null,
            form: {
              // brand_id: null,
              // model_id: null,
              modelTitle: null,
              regnumber: null,
              property: 2,
            }
        }
    },
    validations: {
      form: {
        // brand_id: {
        //   required,
        // },
        // model_id: {
        //   required,
        // },
        modelTitle: {
          required,
        },
        regnumber: {
          required,
        },
      }
    },
    created: function () {
      var self = this;
      self.api.get('reference-book/brands')
      .then(function(response) {
        self.options.brands = response.data;
      }); 
    },
    methods: {
      validateState(name) {
        const { $dirty, $error } = this.$v.form[name];
        return $dirty ? !$error : null;
      },
      onBrandSelected() {
          var self = this;
          self.api.get('reference-book/models', {"brand_id": this.form.brand_id})
          .then(function(response) {
            self.options.models = response.data;
          }); 
      },
      selectPTS() {
        var self = this;
        var reader = new FileReader();
        if(self.photo_pts) {
          reader.readAsDataURL(self.photo_pts);
          reader.onload = function() {
            self.form.photo_pts = reader.result;
          }
        }
      },
      selectDL() {
        var self = this;
        var reader = new FileReader();
        if(self.photo_dl) {
          reader.readAsDataURL(self.photo_dl);
          reader.onload = function() {
            self.form.photo_dl = reader.result;
          }
        }
      },
      prevStep() {
        this.$parent.changeStep(this.step - 1);
      },
      nextStep() {
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          return;
        }
        this.$parent.changeStep(this.step + 1);
        this.$parent.setFormData(this.form);
      }
    }
}
</script>