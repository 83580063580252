<template>
    <div>
        <div v-if="1 === order.pay_method_1">Наличные</div>
        <div v-if="1 === order.pay_method_2">Перевод на карту</div>
        <div v-if="1 === order.pay_method_3">Оплата по счету (с НДС)</div>
        <div v-if="1 === order.pay_method_4">Оплата по счету (без НДС)</div>
    </div>
</template>
<script>
export default {
    name: 'ValuePayments',
    props: ['order'],
}
</script>