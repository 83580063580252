<template>
    <div class="order-item">
        <div class="row">
            <div class="col-7">
                <h2>{{ tech.title }}</h2>
                <div class="order-detail">
                    <strong>Вид:</strong> {{ tech.categoryTitle }}
                </div>
                <div class="order-detail">
                    <strong>Марка:</strong> {{ tech.brandTitle }}
                </div>
                <div class="order-detail">
                    <strong>Модель:</strong> {{ tech.modelTitle }}
                </div>
                <div class="order-detail">
                    <strong>Цена:</strong> {{ tech.priceTitle }}
                </div>

                <div class="order-detail">
                    <strong>Статус:</strong> {{ tech.statusTitle }}
                </div>
            </div>
            <div class="col-4 offset-1">
                <div class="img-wrapper img-wrapper-square">
                    <img v-bind:src="pic" alt="" style="display:block; max-width:100%;">
                </div>
            </div>
        </div>
        <router-link :to="{ 'name': 'techShow', 'params': { tech_id: tech.id } }">Подробнее &rarr;</router-link>
    </div>
</template>

<script>
export default {
    name: "TechItem",
    props: ["tech"],
    data() {
        return {
            pic: ''
        };
    },
    created: function() {
        if(this.tech.photos.length > 0) {
            this.pic = this.tech.photos[Object.keys(this.tech.photos)[0]]; 
        } else {
            this.pic = 'icons/excavator_bw.svg';
        }
    },
    methods: {}
};
</script>
