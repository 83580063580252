<template>
    <div id="index">
        <div class="container">
            <div class="text-center">
                <h2 class="mb-4">{{ tech.title }}</h2>
                <hr>
            </div>

            <div class="row">
                <div class="col-md-6 offset-md-3">
                    <Carousel v-bind:tech="tech"></Carousel>
                </div>
            </div>

            <TechControlBtn
                v-bind:tech="tech"
            ></TechControlBtn>
            <div class="tech-details mb-4">
                <strong>О технике</strong>
                <div class="tech-detail">
                    Вид: {{tech.categoryTitle}}
                </div>
                <div class="tech-detail">
                    Модель: {{tech.title}}
                </div>
                <div class="tech-detail">
                    Госномер: {{tech.regnumber}}
                </div>
            </div>

            <div class="tech-details mb-4">
                <strong>Характеристики</strong>
                <div class="tech-detail tech-detail-separate clearfix"
                    v-for="param in tech.params"
                    :key="param.id">
                    <div class="float-right"><span>{{param.text}}</span></div>
                    {{param.label}}
                </div>
            </div>

<!--
            <div class="tech-details mb-4">
                <strong>Цена</strong>
                <div class="tech-detail clearfix">
                    <div class="float-right">/ час</div>
                    {{tech.price}} 
                </div>
                <div class="tech-detail">
                    Доставка: {{tech.price_delivery}} 
                </div>
                <div class="tech-detail">
                    Мин. объем работ: {{tech.min_hour}} ч
                </div>
            </div>

            <div class="tech-details mb-4">
                <strong>Способ оплаты (id)</strong>
                {{tech.pay_method_1}}
                {{tech.pay_method_2}}
                {{tech.pay_method_3}}
                {{tech.pay_method_4}}
            </div>
-->
            <div class="tech-details mb-4">
                <strong>География работ</strong>
                <div class="tech-detail">
                    {{tech.regionTitle}}
                </div>
                <div class="tech-detail">
                    {{tech.cityTitle}}
                </div>
            </div>
<!--
            <div class="tech-details mb-4">
                <strong>Вид собственности (id)</strong>
                {{tech.property}}
            </div>

            <div class="tech-details mb-5">
                <strong>Собственник</strong>
                <div class="tech-detail">
                     № ПТС {{tech.pts}}
                </div>
                <div class="tech-detail">
                    Выдан: {{tech.pts_date}}
                </div>
            </div>
-->
            <!-- <div class="text-center mb-5">
                <a href="javascript:void(0)" class="btn btn-lg btn-outline-secondary">В архив</a>
            </div> -->
        </div>
    </div>
</template>

<script>
import Carousel from './Carousel.vue';
import TechControlBtn from './TechControlBtn.vue';
export default {
    name: "TechDetails",
    props: ["tech_id"],
    components: {Carousel, TechControlBtn},
    data() {
        return {
            tech: {}
        };
    },
    created: function() {
        if (!this.tech_id) {
            this.$router.push({ path: "/tech/list" });
        }
        this.api.get("tech/get", { tech_id: this.tech_id }).then(
            function(response) {
                this.tech = response.data;
                console.log(response.data);
            }.bind(this)
        );
    },
    methods: {}
};
</script>