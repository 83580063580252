<template>
    <div>
        <div class="text-center mt-5">
            <a v-if="4 != order.status"
                href="javascript:"
                class="btn btn-lg btn-outline-secondary"
                @click="actionToArchive"
                >В архив
            </a>&nbsp;
            <a v-if="4 == order.status"
                href="javascript:"
                class="btn btn-lg btn-outline-secondary"
                @click="actionFromArchive"
                >Вернуть в список
            </a>&nbsp;
            <a v-if="4 == order.status"
                href="javascript:"
                class="btn btn-lg btn-outline-secondary"
                @click="actionDelete"
                style="display:none;"
                >Удалить
            </a>
        </div>
    </div>
</template>

<script>
export default {
    name: "OrderControlBtn",
    props: ["order"],
    data() {
        return {};
    },
    methods: {
        actionToArchive: function() {
            this.api.get("order/change/status", { order_id: this.order.id, status: 4}).then(
                function() {
                    this.$router.push({ path: "/orders/client" });
                }.bind(this)
            );
        },
        actionFromArchive: function() {
            this.api.get("order/change/status", { order_id: this.order.id, status: 1}).then(
                function() {
                    this.$router.push({ path: "/orders/client" });
                }.bind(this)
            );
        },
        actionDelete: function() {
            this.api.get("order/delete", { order_id: this.order.id}).then(
                function() {
                    this.$router.push({ path: "/orders/client" });
                }.bind(this)
            );
        }
    }
};
</script>
