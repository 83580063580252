<template>
    <div style="display: inline-block">
        <a
            href="javascript:"
            class="btn btn-sm btn-link"
            @click="openModal"
            ><img src="icons/filter.svg" alt="" style="width: 24px"/>
        </a>
        <b-modal
            id="orderSearchModal"
            hide-footer
            hide-header
        >
            <div class="text-left">
                <div>
                    <b-form-group label-cols="4" id="input-group-1" label="Услуга:" label-for="select-1">
                        <b-form-select class="no-border-input" v-model="form.category_id" :options="options.categories"></b-form-select>
                    </b-form-group>
                    <b-form-group label-cols="4" id="input-group-2" label="Регион:" label-for="select-2">
                        <b-form-select class="no-border-input" @change="onRegionSelected($event)" v-model="form.region_id" :options="options.regions"></b-form-select>
                    </b-form-group>
                    <b-form-group label-cols="4" id="input-group-3" label="Город:" label-for="select-3" v-if="options.cities.length">
                        <b-form-select class="no-border-input" v-model="form.city_id" placeholder="Выберите город" :options="options.cities"></b-form-select>
                    </b-form-group>
                    <b-form-group label-cols="4" id="input-group-4" label="Начало работ от:" label-for="select-4">
                        <b-form-datepicker 
                            id="datepicker_time_start_from" 
                            v-model="form.date_start_from" 
                            class="mb-2 no-border-input"
                            placeholder="Выберите дату">
                        </b-form-datepicker>
                        <b-form-timepicker 
                            id="timepicker_time_start_from" 
                            class="no-border-input"
                            v-model="form.time_start_from" 
                            placeholder="Выберите время"
                            v-bind="labels[locale] || {}"
                            locale="locale">
                        </b-form-timepicker>
                    </b-form-group>
                    <b-form-group label-cols="4" id="input-group-4" label="Начало работ до:" label-for="select-4">
                        <b-form-datepicker 
                            id="datepicker_time_start_to" 
                            v-model="form.date_start_to" 
                            class="mb-2 no-border-input"
                            placeholder="Выберите дату">
                        </b-form-datepicker>
                        <b-form-timepicker 
                            id="timepicker_time_start_to" 
                            class="no-border-input"
                            v-model="form.time_start_to" 
                            placeholder="Выберите время"
                            v-bind="labels[locale] || {}"
                            locale="locale">
                        </b-form-timepicker>
                    </b-form-group>
                </div>
                <div class="inline-flex">
                    <a
                        @click="resetModal"
                        class="btn btn-outline-secondary btn-lg btn-block mt-3 mr-1"
                        >Сбросить
                    </a>
                    <a
                        @click="applyModal"
                        class="btn btn-secondary btn-lg btn-block mt-3 ml-1"
                        >Применить
                    </a>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "orderSearchModal",
    data() {
        return {
            options: {
                categories: [],
                regions: [],
                cities: [],
            },
            form: {},
            locale: 'ru',
            locales: [
                { value: 'ru', text: 'Русский' }
            ],
            labels: {
                ru: {
                    labelHours: 'Часы',
                    labelMinutes: 'Минуты',
                    labelSeconds: 'Секунды',
                    labelIncrement: 'Увеличить',
                    labelDecrement: 'Уменьшить',
                    labelSelected: 'Выберите время',
                    labelNoTimeSelected: 'Время не выбрано',
                    labelCloseButton: 'Закрыть'
                }
            }
        }
    },
    created: function () {
        this.api.get('reference-book/regions')
        .then(function(response) {
            this.options.regions = response.data;
        }.bind(this)); 

        this.api.get('reference-book/tech-categories')
        .then(function(response) {
            this.options.categories = response.data;
        }.bind(this));
    },
    methods: {
        onRegionSelected() {
            this.api.get('reference-book/cities', {"region_id": this.form.region_id})
            .then(function(response) {
                this.options.cities = response.data;
            }.bind(this)); 
        },
        openModal: function() {
            this.$bvModal.show("orderSearchModal");
        },
        applyModal: function() {
            this.$parent.setSearchData(this.form);
            this.$bvModal.hide("orderSearchModal");
        },
        resetModal: function() {
            this.form = {};
            this.options.cities = [];
            this.$parent.setSearchData(this.form);
        }
    }
};
</script>
