<template>
  <div id="index">
    <div class="container">
      <div class="row">
        <div class="col-md-4 offset-md-4">
          <div class="row mb-4">
            <div class="col-4">
              <img v-bind:src="this.$store.state.user.avatar" class="img-fluid rounded-circle">
            </div>
            <div class="col-6">
                <h4>{{ this.$store.state.user.firstname }} {{ this.$store.state.user.lastname }}</h4>
                <b-button-group>
                    

                    <a href="javascript:" class="btn btn-sm" v-on:click="changeRole(maker)" v-bind:class="[maker == this.$store.state.user.role_id ? 'btn-primary' : 'btn-outline-primary']">Заказчик</a>
                    <a href="javascript:" class="btn btn-sm" v-on:click="changeRole(client)" v-bind:class="[client == this.$store.state.user.role_id ? 'btn-primary' : 'btn-outline-primary']">Исполнитель</a>
                </b-button-group>
            </div>
          </div>
          <hr>
          
          <div v-if="client == this.$store.state.user.role_id" class="nav-grid">
              <router-link to="/tech/add" class="nav-block">
                <div class="img-wrapper">
                  <img src="/icons/plus_bw.svg">
                </div>
                <span class="nav-block-label">Добавить технику</span>
              </router-link>

              <router-link to="/tech/list" class="nav-block">
                <div class="img-wrapper">
                  <img src="/icons/excavator_bw.svg">
                </div>
                <span class="nav-block-label">Мои услуги</span>
              </router-link>

              <router-link to="/orders/search" class="nav-block">
                <div class="img-wrapper">
                  <img src="/icons/search_bw.svg">
                </div>
                <span class="nav-block-label">Найти заказы</span>
              </router-link>

              <router-link to="/orders/worker" class="nav-block">
                <div class="img-wrapper">
                  <img src="/icons/list_bw.svg">
                </div>
                <span class="nav-block-label">Мои заказы</span>
              </router-link>
          </div>

          <div v-if="maker == this.$store.state.user.role_id" class="nav-grid">

              <router-link to="/orders/create" class="nav-block">
                <div class="img-wrapper">
                  <img src="/icons/plus_bw.svg">
                </div>
                <span class="nav-block-label">Сделать заказ</span>
              </router-link>

              <router-link to="/orders/client" class="nav-block">
                <div class="img-wrapper">
                  <img src="/icons/list_bw.svg">
                </div>
                <span class="nav-block-label">Мои заказы</span>
              </router-link>
              
          </div>

          <div class="row" v-if="maker != this.$store.state.user.role_id">
              <div class="col-12">
                <router-link to="/profile/money">
                  <div class="card">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-4">
                          <div class="img-wrapper">
                            <img src="/icons/wallet_bw.svg">
                          </div>
                        </div>
                        <div class="col-6">
                            <span>Мой баланс:</span>
                            <h4>{{this.$store.state.user.balanceStr}} <sup class="text-muted">@</sup></h4>
                        </div>
                      </div>                   
                    </div>
                  </div>
                </router-link>
              </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixinAuth from './../mixins/mixinAuth';

export default {
    mixins: [mixinAuth],
    name: 'DashBoard',
    data() {
      return {
        maker: 1, // исполнитель
        client: 2 //заказчик
      }
    },
    methods: {
      changeRole(role_id) {        
        var self = this;
        self.api.get('profile/change_role', {"role_id": role_id})
        .then(function() {
          self.loadUserInfo();
        }); 
      }
    } 
}
</script>